import React from "react";
import { Icon } from "semantic-ui-react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";

const ProjectsPage = () => (
	<Layout>
		<Helmet title="Projects"/>
		<p>Updating soon...<Icon loading name="cog"/></p>
	</Layout>
);

export default ProjectsPage;
